import React, { useEffect, useRef } from 'react';

import classes from './Header.module.css';
import Intro from '../../components/Intro/Intro';
import Arrow from '../../components/ui/Arrow/Arrow';

const Header = () => {
  const headerRef = useRef();

  // Override 100vh with current height to stop shifting on Android
  useEffect(() => {
    headerRef.current.style.height = `${headerRef.current.offsetHeight}px`;
  }, []);

  return (
    <section className={classes.Header} id="top" ref={headerRef}>
      <Intro />
      <Arrow down scrollToId="#experience" />
    </section>
  );
};

export default Header;
