import React from 'react';
import classes from './Arrow.module.css';

const Arrow = ({ up, scrollToId }) => {
  const bobClasses = [classes.Arrow];
  const iconClasses = ['fas'];

  if (up) {
    bobClasses.push(classes.up);
    iconClasses.push('fa-chevron-up');
  } else {
    bobClasses.push(classes.down);
    iconClasses.push('fa-chevron-down');
  }

  return (
    <a
      href={scrollToId}
      className={bobClasses.join(' ')}
      aria-label={up ? 'Scroll to top' : 'Scroll down'}
    >
      <i className={iconClasses.join(' ')} />
    </a>
  );
};

export default Arrow;
