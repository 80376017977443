import React from 'react';

import './App.css';
import Header from './containers/Header/Header';
import Experience from './containers/Experience/Experience';
import Projects from './containers/Projects/Projects';
import Footer from './components/Footer/Footer';
import Arrow from './components/ui/Arrow/Arrow';

const App = () => {
  return (
    <main className="App">
      <Header />
      <Experience />
      <Projects />
      {/* <About /> */}
      <div className="up-arrow-container">
        <Arrow up scrollToId="#top" />
      </div>
      <Footer />
    </main>
  );
};

export default App;
