import React from 'react';

import classes from './Footer.module.css';
import avatar from '../../assets/images/avatar-300.png';

const Footer = () => {
  return (
    <section className={classes.Footer}>
      <div className={classes.text}>
        <p>
          Avatar by Brendan Carter
          <br />
          Icons by Font Awesome
        </p>
        <p>&copy; {new Date().getFullYear()} Derek Czysz</p>
      </div>

      <img src={avatar} alt="avatar" />
    </section>
  );
};

export default Footer;
