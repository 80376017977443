import React from 'react';

import classes from './Project.module.css';
import ProjectTechIcon from './ProjectTechIcon/ProjectTechIcon';

const Project = ({ name, url, image, tech, desc, repo }) => {
  const projectTechIcons = tech.map(t => (
    <ProjectTechIcon key={t} iconClass={t} />
  ));

  const buttonClasses = [classes.buttons];
  if (!repo) buttonClasses.push(classes.disabled);

  return (
    <div className={classes.Project}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className={classes.image}>
          <img src={image} alt="Project screenshot" />
        </div>
      </a>

      <h2>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </h2>

      <p>{desc}</p>

      <div className={classes.tech}>
        <ul>{projectTechIcons}</ul>
      </div>

      <div className={buttonClasses.join(' ')}>
        <a href={repo} target="_blank" rel="noopener noreferrer">
          View Repo
        </a>
      </div>
    </div>
  );
};

export default Project;
