import React from 'react';

import classes from './ProjectTechIcon.module.css';

const ProjectTechIcon = ({ iconClass }) => {
  return (
    <li className={classes.ProjectTechIcon}>
      <i className={iconClass} />
    </li>
  );
};

export default ProjectTechIcon;
