import React from 'react';

import classes from './IntroLinks.module.css';
import IntroLink from './IntroLink/IntroLink';

let links = [
  {
    name: 'Email',
    url: '',
    iconClass: 'far fa-envelope',
  },
  {
    name: 'Github',
    url: 'https://github.com/dczysz',
    iconClass: 'fab fa-github',
  },
  {
    name: 'Codepen',
    url: 'https://codepen.io/spottedcow',
    iconClass: 'fab fa-codepen',
  },
];

const IntroLinks = () => {
  //TODO: This could be better
  const buildEmail = () => {
    const u = 'dczysz',
      p = 'protonmail',
      d = 'com',
      e = `${u}@${p}.${d}`;

    return links.map((link) => {
      if (link.name !== 'Email') return { ...link };

      return {
        ...link,
        url: `mailto:${e}`,
      };
    });
  };

  const introLinks = buildEmail(links).map((link) => (
    <IntroLink key={link.name} {...link} />
  ));
  return <ul className={classes.IntroLinks}>{introLinks}</ul>;
};

export default IntroLinks;
