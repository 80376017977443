import React from 'react';

import classes from './ExperienceList.module.css';
import ExperienceIcon from './ExperienceIcon/ExperienceIcon';

const ExperienceList = ({ heading, content }) => {
  return (
    <div className={classes.ExperienceList}>
      <h2>{heading}</h2>
      <ul>
        {content.map(iconClass => (
          <ExperienceIcon key={iconClass} name={iconClass} />
        ))}
      </ul>
    </div>
  );
};

export default ExperienceList;
