import React from 'react';

import classes from './ExperienceIcon.module.css';

const ExperienceIcon = ({ name }) => {
  const getFAClass = name => {
    switch (name) {
      case 'HTML5':
        return 'fab fa-html5';
      case 'CSS3':
        return 'fab fa-css3-alt';
      case 'JavaScript':
        return 'fab fa-js';
      case 'React':
        return 'fab fa-react';
      case 'Java':
        return 'fab fa-java';
      case 'Git':
        return 'fab fa-git';
      case 'Vue':
        return 'fab fa-vuejs';
      case 'PHP':
        return 'fab fa-php';
      case 'Python':
        return 'fab fa-python';
      case 'Android':
        return 'fab fa-android';
      default:
        return '';
    }
  };

  return (
    <li className={classes.ExperienceIcon}>
      <i className={getFAClass(name)} />
      <label className={classes.label}>{name}</label>
    </li>
  );
};

export default ExperienceIcon;
