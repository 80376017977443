import React from 'react';

import classes from './IntroLink.module.css';

const IntroLink = ({ name, url, iconClass }) => {
  return (
    <li className={classes.IntroLink}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={name}
      >
        <i className={iconClass} />
      </a>
      <label className={classes.label}>{name}</label>
    </li>
  );
};

export default IntroLink;
