import React from 'react';

import classes from './Intro.module.css';
import img from '../../assets/images/avatar-300.png';
import IntroLinks from './IntroLinks/IntroLinks';

const Intro = () => {
  return (
    <div className={classes.Intro}>
      <div className={classes.topRow}>
        <div className={classes.avatar}>
          <img src={img} alt="Avatar" />
        </div>

        <div className={classes.text}>
          <h2>I'm Derek,</h2>
          <p>
            a software developer with a wide range of experience, currently
            focusing on web development.
          </p>
          <p>
            I love learning new languages and technologies and have a hard time
            stepping away from an exciting project once I start.
          </p>
        </div>
      </div>

      <IntroLinks />
    </div>
  );
};

export default Intro;
