import screwsImg from '../assets/images/screenshots/screws.png';
import snippetyImg from '../assets/images/screenshots/snippety.png';
import waterWasteImg from '../assets/images/screenshots/water-waste.png';
import narrowGateGamesImg from '../assets/images/screenshots/narrow-gate-games.png';

export const experienceData = {
  experience: {
    heading: 'Proficient With',
    content: ['HTML5', 'CSS3', 'JavaScript', 'React', 'Git'],
  },
  familiar: {
    heading: 'Experience With',
    content: ['Vue', 'Python', 'Java', 'Android'],
  },
};

export const projectData = [
  {
    name: 'Screws',
    url: 'https://scre.ws',
    repo: 'https://github.com/pot0rpan/screws',
    image: screwsImg,
    desc: 'URL shortener focused on user privacy and security, built with Next.js, TypeScript, and MongoDB',
    tech: ['fab fa-html5', 'fab fa-css3-alt', 'fab fa-js', 'fab fa-react'],
  },
  {
    name: 'Narrow Gate Games',
    url: 'https://narrowgategames.com',
    image: narrowGateGamesImg,
    desc: 'Board game designer portfolio with newsletter signup and RSS feed, built with Next.js, TypeScript, and Sanity CMS',
    tech: ['fab fa-html5', 'fab fa-css3-alt', 'fab fa-js', 'fab fa-react'],
  },
  {
    name: 'Snippety',
    url: 'https://snippety.netlify.com',
    repo: 'https://github.com/dczysz/snippety',
    image: snippetyImg,
    desc: 'Create pretty code snippets to save as PNG or JPG images, built with React, TypeScript, and Redux',
    tech: ['fab fa-html5', 'fab fa-css3-alt', 'fab fa-js', 'fab fa-react'],
  },
  {
    name: 'Water Waste',
    url: 'https://water-waste.now.sh',
    repo: 'https://github.com/dczysz/water-waste',
    image: waterWasteImg,
    desc: 'Simple site showing the amount of water used in food production, built with Next.js and TypeScript to learn Next.js',
    tech: ['fab fa-html5', 'fab fa-css3-alt', 'fab fa-js', 'fab fa-react'],
  },
];
