import React from 'react';

import classes from './Experience.module.css';
import ExperienceList from '../../components/ExperienceList/ExperienceList';
import { experienceData } from '../../data/data';

const Experience = () => {
  return (
    <section className={classes.Experience} id="experience">
      <h1>
        Languages
        <br />
        <span>&</span>
        <br />
        Technologies
      </h1>
      <div className={classes.box}>
        <ExperienceList
          heading={experienceData.experience.heading}
          content={experienceData.experience.content}
        />
        <ExperienceList
          heading={experienceData.familiar.heading}
          content={experienceData.familiar.content}
        />
      </div>
    </section>
  );
};

export default Experience;
