import React from 'react';

import classes from './Projects.module.css';
import Project from '../../components/Project/Project';
import { projectData } from '../../data/data';

const Projects = () => {
  const projects = projectData.map((proj) => (
    <Project key={proj.name} {...proj} />
  ));

  return (
    <div className={classes.Projects}>
      <h1>Featured Projects</h1>
      <div className={classes.projectCards}>{projects}</div>
    </div>
  );
};

export default Projects;
